import PublicTopNav from "../components-reusable/Navigation/PublicTopNav/PublicTopNav";
import Footer from "../components-reusable/Navigation/Footer/Footer";
import Header from "../components-page/Home/Header/Header";
import LogoCloud from "../components-page/Home/LogoCloud/LogoCloud";
import FeatureGrid from "../components-page/Home/FeatureGrid/FeatureGrid";
import CallToAction from "../components-page/Home/CallToAction/CallToAction";
import LargeFeatureRow from "../components-page/Home/LargeFeatureRow/LargeFeatureRow";
import {
  ClockIcon,
  GlobeIcon,
  HomeIcon,
  MapIcon,
} from "@heroicons/react/outline";
import meditatingPerson from "../public/images/3d-casual-life-young-woman-meditating.webp";
import * as animationData from "../public/lottie/animation_data";
import * as animationData2 from "../public/lottie/animation_data2";
import * as animationData3 from "../public/lottie/animation_data3";
import FormCallToAction from "../components-page/Home/FormCallToAction/FormCallToAction";
import { ThemeContext } from "../contexts/ThemeContext/ThemeContext";
import { useContext, useEffect } from "react";
import WebsiteChat from "../components-reusable/Common/WebsiteChat/WebsiteChat";
import FancyUnderline from "../components-reusable/Common/FancyUnderline/FancyUnderline";
import FeaturedBlogArticles from "../components-page/BlogPost/FeaturedBlogArticles/FeaturedBlogArticles";
import * as contentful from "contentful";
const client = contentful.createClient({
  space: "bsnbd6wv79oo",
  environment: "master",
  accessToken: "ZJ8ZaSD5Uc11ITplKX0sTrxNHaHlLJGjnyAN5szS8mo",
});

export default function LandingPage({ featuredArticles }) {
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => {
    setTheme("light");
  }, []);

  return (
    <div className="bg-white">
      <WebsiteChat />
      <PublicTopNav />
      <Header
        title={
          <>
            <span className="block text-gray-800">Your cleaning service,</span>{" "}
            <span className="block text-transparent bg-clip-text bg-gradient-to-r from-primary-500 to-primary-900 pb-4">
              <FancyUnderline>automated.</FancyUnderline>
            </span>
          </>
        }
      />
      <LogoCloud />
      <FeatureGrid />
      <LargeFeatureRow
        badgeName="Dashboard"
        BadgeIcon={HomeIcon}
        title={
          <>
            Grow your business
            <br />
            <FancyUnderline>without</FancyUnderline> the headaches
          </>
        }
        description="Tap into super powers to grow and streamline
        your service business. Focus on your actual service while leads,
        client bookings, and invoicing run on autopilot."
        features={[
          "Every client, provider, and order in one place",
          "Manage your business from anywhere",
          "Invite your entire team for no extra cost",
        ]}
        imageLocation="right"
        image={meditatingPerson}
        imageMaxWidth="max-w-[300px] sm:max-w-[400px] w-full"
      />
      <FormCallToAction />
      <LargeFeatureRow
        badgeName="Dispatch"
        BadgeIcon={MapIcon}
        title={
          <>
            Schedule and
            <br />
            dispatch service pros
          </>
        }
        description="Spend less time assigning new service requests with our
        automated dispatching. Our system will dynamically notify and
        assign your service pros based on location, schedule, and skill
        set."
        features={[
          "Automated dispatching via SMS",
          "Single day dispatch map",
          "Round robin and distance based matching",
        ]}
        imageLocation="left"
        lottie={animationData}
      />
      <LargeFeatureRow
        badgeName="Locations"
        BadgeIcon={GlobeIcon}
        title={<>Custom service area</>}
        description=" Define exactly where customers can order from. Draw any shape on a map and allow orders to be placed from inside those service zones. Each service area can have a unique schedule to control when orders are placed. Add as many zones to create the perfect service area and schedule for your business!"
        features={[]}
        imageLocation="right"
        lottie={animationData2}
      />
      <LargeFeatureRow
        badgeName="Schedule"
        BadgeIcon={ClockIcon}
        title={<>Dynamic scheduling</>}
        description="Build the perfect schedule with Tap For Service. For each service area, you can enter time slots and availability. These will be shown to your clients are they request service from you. Prevent overbooking by adding a maximum order count for each time slot. Update anytime and control your schedule automatically."
        features={[]}
        imageLocation="left"
        lottie={animationData3}
      />
      <FeaturedBlogArticles
        title="Guides to Grow Your Business"
        articles={featuredArticles}
        showMore
      />
      <CallToAction />
      <Footer />
    </div>
  );
}

export async function getStaticProps(context) {
  const featuredArticles = await client.getEntries({
    content_type: "blogPost",
    limit: 6,
  });

  return {
    props: {
      featuredArticles: featuredArticles.items,
    },
  };
}
