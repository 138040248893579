import { ArrowRightIcon } from "@heroicons/react/outline";
import Image from "next/image";
// import homepageImage from "../../../public/images/homepage_hero.webp";
import homepageImage from "../../../public/images/сleaning_supplies.webp";

const Header = ({ title }) => {
  return (
    <div className="max-w-7xl mx-auto lg:flex items-center py-16 sm:py-0">
      <div className="pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32 lg:max-w-[900px] flex-1">
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-5xl tracking-tight font-semibold text-gray-900 sm:text-6xl md:text-7xl">
              {title}
            </h1>
            <p className="mt-3 text-gray-600 max-w-[400px] sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
              Bring your scheduling, invoicing, and dispatching into one
              delightful app that works anywhere.
            </p>
            <div className="mt-5 sm:mt-8 flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="/demo"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10"
                >
                  Request a demo
                  <ArrowRightIcon className="ml-2 w-6 h-6" />
                </a>
              </div>
              {/* <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="/signup"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200 md:py-4 md:text-lg md:px-10"
                >
                  
                </a>
              </div> */}
            </div>
            <p className="mt-3 text-sm text-gray-500">
              30-day free trial. No credit card required.
            </p>
          </div>
        </main>
      </div>
      <div className="flex justify-center">
        <div className="w-[300px] sm:w-[450px]">
          <Image
            layout="responsive"
            priority
            src={homepageImage}
            alt="colorful building illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
