import Link from "next/link";
import moment from "moment";
import SecondaryButton from "../../../components-reusable/Buttons/SecondaryButton/SecondaryButton";

export default function FeaturedBlogArticles({
  articles,
  title = "Recommended Posts",
  showMore = false,
}) {
  return (
    <div className="pt-8 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
            {title}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Helpful tips and information to automate your business.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {articles.map((post) => {
            const {
              title,
              featuredImage,
              category,
              slug,
              publishDate,
              authorName,
              description,
            } = post.fields;

            const momentpPublishDate = moment(publishDate);

            return (
              <Link key={title} href={`/blog/${slug}`}>
                <div className="flex flex-col rounded-lg hover:shadow-lg shadow cursor-pointer overflow-hidden">
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={featuredImage.fields.file.url}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-primary-600">
                        {category}
                      </p>
                      <a href={""} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                          {title}
                        </p>
                        <p className="mt-3 text-base text-gray-500 line-clamp-3">
                          {description}
                        </p>
                      </a>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="">
                        <p className="text-sm font-medium text-gray-900">
                          {authorName}
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          Posted on
                          <time dateTime={publishDate} className="ml-1">
                            {momentpPublishDate.format("ll")}
                          </time>
                          {/* <span aria-hidden="true">&middot;</span>
                          <span>5 read</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {showMore && (
          <div className="flex items-center justify-center mt-8">
            <SecondaryButton size="xl" href="/blog">
              View more
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  );
}
